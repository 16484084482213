jQuery(function($){
    if ($('body.template-eventos').length > 0)
    {
        /**************************
     * Listar Restaurantes
     *****************/
    var page = 1;
    var slug = $('.list-group-item-restaurant.active').data('slug');
    var termid = $('.list-group-item-restaurant.active').data('termid');
    var location = $('.list-group-item-restaurant.active').data('location');
    //var taxname = $('.list-group-item.active').data('taxname');
    var listarEventosAjax = function (page, termid, slug, location) {
        //alert ('foi');
        $.ajax({
            url:ajaxwp.ajaxurl,
            type:'GET',
            data:{
                action: 'listarEventos',
                page: page,
                termid:termid,
                slug:slug,
                location:location
            },
            beforeSend:function () {
               console.log('A carregar Eventos');
                $('.loader').addClass('loading');
                $('.progress').removeClass('d-none');
                //$("body").addClass('block-scroll'); 
               
                
            }
        })
        .done(function(response){
            console.log('Eventos carregados');
           
            $("main").toggleClass('block-scroll'); 
            
            $('.progress').addClass('d-none');
           // $("body").removeClass('block-scroll'); 
            $('#listar-eventos').html(response);
            //response.stopPropagation();
        })
        .fail(function(){
            console.log('opss erro a carregar Eventos!!!');
        });
    };
    listarEventosAjax(page, termid,slug,location);
   
    //Ação do botão da categoria
    $('.list-group-item-restaurant').on('click', function () {
       // alert ("teste");
       slug = $(this).data('slug');
       termid = $(this).data('termid');
       banner= $(this).data('banner');
       console.log(banner);
       $('.main-banner').fadeTo('fast',0)
       setTimeout(function() {
            $('.main-banner').css({'background-image': 'url(' + banner + ')'});
            $('.main-banner').fadeTo('fast',1);
       
        }, 100);
       //taxname = $(this).data('taxname');
       listarEventosAjax(1, termid,slug,location);
       $('.list-group-item-restaurant').removeClass('active');
       $(this).addClass('active');
    });


    //Ação do botão da paginação
    $('body').on('click', '.page-item', function () {
        // alert ("teste");
        page = $(this).find('span').text();
        var slug = $('.list-group-item-restaurant.active').data('slug');
        listarEventosAjax(page, termid, slug,location);
       // console.log(slug);
        $('.page-item').removeClass('active');
        $(this).addClass('active');
 
    });


   
    /******************************************************************************** */
    }
    


    

});